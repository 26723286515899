const regexUrl = /(?:https?:\/\/)?(?:www\.)?(([-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b)+){1}(\/[\/\d\w\.,\+-]*)*((?:[\?]){1}(\S+)*)*(\#(\S+)*)*/gi;
const regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const regexPhone = /((?:([+]\d{1,4})[-.\s]?)?(?:[(](\d{1,3})[)][-.\s]?)?(\d{1,4})[-.\s]?(\d{1,4})[-.\s]?(\d{1,9})){2}/g;
const regexDate = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

const regexPhoneLabelWithContainer = /(?<=!\[)Phone( (SMS|CALL))?(?=\]\[(\+\d{1,3})?[\d\s\(\)-]{7,18}\])/;
const regexPhoneWithContainer = /!\[Phone( (SMS|CALL))?\]\[(\+\d{1,3})?[\d\s\(\)-]{7,18}\]/g;
const regexPhoneFromContainer = /(?<=!\[Phone( (SMS|CALL))?\]\[)(\+\d{1,3})?[\d\s\(\)-]{7,18}(?=\])/;

const regexUrlImageContainer = /\!\[Imagen(\s\d+)?\]\(.+\)/g;
const regexUrlImageFromContainer = /(?<=\!\[Imagen(\s\d+)?\]\().+(?=\))/;

const regexCleanPhone = /[^\d+]/g;

const iconArrowRedirect = '<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20"><path d="M17 17H3V3h5V1H3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5h-2z"/><path d="m11 1l3.29 3.29l-5.73 5.73l1.42 1.42l5.73-5.73L19 9V1z"/></svg>';

let langStrings = 'en';
let typeTextForHyperLinks = 1; // 1 => Texto, 2 => Icono, 3 => Texto e icono.


/**
 * Del string se obtiene urls, emails, telefonos, para reemplazarlos por hipervinculos, utilizando el HTML.
 * @param {*} string 
 * @returns 
 */
export const processString = ( string, lang = 'en', typeTextHyperLinks = 1 ) => {
    const arrayElements = getArrayElements( string );

    const arrayElements2 = getArrayElementContainers( string );

    langStrings = lang;

    if( typeTextHyperLinks > 0 && typeTextHyperLinks < 4 )
        typeTextForHyperLinks = typeTextHyperLinks;
    
    return getStringWithHiperlinks( string, arrayElements, arrayElements2 );
}

/**
 * Retorna un array con phones, emails y websites, encontrados en el string.
 * @param {*} string 
 * @returns 
 */
export const getContactElementsFromString = ( string ) => {

    const arrayElements = getArrayElements( string );

    // console.log("Elementos de contacto del mensaje", arrayElements);

    const phones = [];
    const emails = [];
    const websites = [];

    if( arrayElements.length )
    arrayElements.map( item => {

        if( checkStringIsEmail( item ) )
            emails.push( item );

        else if( checkStringIsPhone( item ) && !checkStringIsUrl( item ) && !checkIfStringHasHttp( item ) )
            phones.push( item );

        else
        {
            const urlHeader = checkIfStringHasHttp( item ) ? '' : 'https://';

            websites.push( urlHeader + item );
        }

    } );

    return { 
        phones: getUniqueValuesInArray(phones), 
        emails: getUniqueValuesInArray(emails), 
        websites: getUniqueValuesInArray(websites)
    };
}

/**
 * Retorna un array con phones, emails, websites e imagenes, encontrados en el string.
 * 
 * Utilizando una estructura pre establecida para ciertos elementos y despues sin una estrucutura para 
 * solo utilizar la estructura base del elemento a buscar.
 * 
 * @param {*} string 
 * @returns 
 */
export const getContactElementsTemplatesFromString = ( string ) => {

    const arrayElements = getArrayElementContainers( string );

    // console.log( "elemnts del template", arrayElements )

    const phones = [];
    const phonesSMS = [];
    const phonesCall = [];
    const emails = [];
    const websites = [];
    const images = [];

    if( arrayElements.length )
    arrayElements.map( item => {

        // console.log( "resviando item", item );

        // console.log( "revisando container phone item", checkStringIsPhoneContainer( item ) );

        // Comprueba si tiene la estructura del container para phone
        if( checkStringIsPhoneContainer( item ) )
        {
            const phoneLabel = item.match( regexPhoneLabelWithContainer );
            const phoneItem = item.match( regexPhoneFromContainer );

            // console.log( "phone label", phoneLabel, phoneItem );
            
            if( phoneLabel && phoneItem )
            {
                // console.log( "phone label detalles", phoneLabel[0], phoneItem[0] );

                if( phoneLabel[ 0 ] == "Phone SMS" )
                    phonesSMS.push( phoneItem[ 0 ] )

                else if( phoneLabel[ 0 ] == "Phone CALL" )
                    phonesCall.push( phoneItem[ 0 ] );
                    
                else if( phoneLabel[ 0 ] == "Phone" )
                    phones.push( phoneItem[ 0 ] );
            }
        }
        else if( checkStringIsUrlImageContainer( item ) )
        {
            const imageUrl = item.match( regexUrlImageFromContainer );

            // console.log( "image url container", imageUrl );

            if( imageUrl )
                images.push( imageUrl[ 0 ] );
        }
    } );

    return { 
        phones_both: getUniqueValuesInArray( phones ), 
        phones_call: getUniqueValuesInArray( phonesCall ), 
        phones_sms: getUniqueValuesInArray( phonesSMS ), 
        emails: getUniqueValuesInArray( emails ), 
        websites: getUniqueValuesInArray( websites ),
        images: getUniqueValuesInArray( images )
    };
}

const getArrayElements = ( string ) => {
    var arrayElements = [];

    // Obtiene del string un array de Urls e Emails.
    const arrayUrlsAndEmails = getElementsWithRegex( regexUrl, string );

    // Si no esta vacio el array de url e emails, lo unen al array principal de la función.
    if( arrayUrlsAndEmails !== null )
    arrayElements = arrayElements.concat( arrayUrlsAndEmails );

    // Obtiene del string un array de numeros de telefonos.
    const arrayPhones = getElementsWithRegex( regexPhone, string );

    // Si el array de telefonos no esta vacio, procede a verificar su contenido.
    if( arrayPhones !== null )
    {
        // Crea un array para almacenar los telefonos a descartar.
        const arrayPhoneToDiscard = [];

        // console.log( "telefonos", arrayPhones );

        // // Verifica las url, si un phone se encuentra en las urls, entonces se agrega el phone al array para descartarlo.
        if( arrayUrlsAndEmails && arrayUrlsAndEmails != null && arrayUrlsAndEmails.length )
        {
            // Recorre el array de telefonos para verificar si uno de estos coinciden con los item del array URLs e Emails.
            arrayPhones.map( itemPhone => {

                // Crea variable para almacenar el telefono.
                let tempPhone = itemPhone;

                // Verifica si el telefono tiene el caracter "+" para agregarle un "\" antes del caracter.
                if( tempPhone.indexOf( "+" ) !== -1 )
                {
                    const arrayTemp = tempPhone.split( "+" );

                    if( arrayTemp.length )
                        tempPhone = arrayTemp.join("\\\+");
                }
                
                // Crea una constante de un Regex para verificar la existencia del telefono en un string.
                const regexPhoneInString = new RegExp( tempPhone, "g" );
                // console.log("map para las urls y tels", "regex es", regexPhoneInString.source);

                // Recorre el array de Urls e Emails para buscar coincidencias con el telefono.
                for (let index = 0; index < arrayUrlsAndEmails.length; index++) {
                    const elementUrlAndEmail = arrayUrlsAndEmails[index];

                    // Utiliza el regex para determinar la cantidad de veces que esta presente el telefono en el url o email.
                    const countPhoneInString = ( elementUrlAndEmail.match( regexPhoneInString ) || [] ).length;

                    // console.log("map para las urls y tels", tempPhone, countPhoneInString, elementUrlAndEmail, elementUrlAndEmail.match( regexPhoneInString ));

                    // Si hay coincidencias y el telefono no esta presente en el array de descarte, 
                    // entonces se inserta el telefono en el array de telefonos a descartar.
                    if( countPhoneInString > 0 && arrayPhoneToDiscard.indexOf( itemPhone ) === -1 )
                    {
                        // Se insertara el telefono en el array de descarte la cantidad veces 
                        // que se encontro el telefono en el url o email.
                        for (let index2 = 0; index2 < countPhoneInString; index2++) {
                            
                            arrayPhoneToDiscard.push( itemPhone );
                            
                        }

                        // console.log("encontre una", itemPhone);
                    }
                }
            } );
        }

        // console.log( "lista de descartes telefono", arrayPhoneToDiscard );

        // Une el array de la funcion con el array de los telefonos.
        arrayElements = arrayElements.concat( 
            // Al array de los telefonos se le hace un filtro para descartar los telefonos descartados.
            arrayPhones.filter( 
                phone => {
                    // Crea variable con el resultado del filtro para el item como true.
                    let result = true;

                    // Verifica que si el telefono no tiene una fecha y esta presente en el array de descarte.                    
                    if( !checkStringIsDate( phone ) && arrayPhoneToDiscard.indexOf( phone ) !== -1 )
                    {
                        // Se remeve el telefono del array de descartes.
                        arrayPhoneToDiscard.splice( arrayPhoneToDiscard.indexOf( phone ), 1 );

                        // console.log( "descartando telefono", phone, arrayPhoneToDiscard );

                        // Cambia a false el valor de la variable result,
                        // para que descarte el telefono del array final.
                        result = false;
                    }
                    else if( checkStringIsDate( phone ) )
                        result = false;

                    return result;
                }
            )
        );
    }

    return arrayElements;
}

/**
 * Obtiene los elementos que tienen una estructura pre establecida en el string.
 * 
 * @param {*} string 
 * @returns 
 */
const getArrayElementContainers = ( string ) => {
    var arrayElements = [];

    const arrayUrlImagesContainer = getElementsWithRegex( regexUrlImageContainer, string );

    // Si no esta vacio el array de url e emails, lo unen al array principal de la función.
    if( arrayUrlImagesContainer !== null )
    arrayElements = arrayElements.concat( arrayUrlImagesContainer );


    // Telefonos que tengan un formato
    const arrayPhonesContainer = getElementsWithRegex( regexPhoneWithContainer, string );

    if( arrayPhonesContainer !== null )
    arrayElements = arrayElements.concat( arrayPhonesContainer );


    return arrayElements;
}

export const getArrayElementsUrlImages = ( string ) => {
    let arrayElements = [];

    const arrayUrlsImages = getElementsWithRegex( regexUrlImageContainer, string );

    if( arrayUrlsImages && arrayUrlsImages.length )
    {
        // console.log("array en required", arrayUrlsImages);

        for (let index = 0; index < arrayUrlsImages.length; index++) {
            const element = arrayUrlsImages[index];
            
            if( element && typeof element === "string" )
            {
                const isAUrlImage = checkStringIsUrlImage( element );
                
                // console.log( "item url a revisar", element, typeof element, isAUrlImage );
    
                if( isAUrlImage )
                {
                    const regexItemUrlImage = element.match( regexUrlImageFromContainer );

                    // console.log("revisa este regex", rege, rege[0]);

                    arrayElements.push( regexItemUrlImage[ 0 ] );
                }
            }
        }
    }

    return arrayElements;
}

const getElementsWithRegex = ( regex, string ) => {
    var matches = string.match( regex );

    // console.log( "Antes de filtrar", matches );

    if( matches !== null )
    matches = matches.filter( n => n != "" );

    // console.log( "El resultado es", matches );

    return matches;
}

const getStringWithHiperlinks = ( string, elements, elements2 = [] ) => {

    if( !string.length || ( !elements.length && !elements2.length ) ) return string;

    // Si el string tiene HTML se inserta en el target si se retorna.
    if( isHTML( string ) )
    {
        // targetFinal.innerHTML = string;
        return string;
    }

    // Recorriendo el array de elementos, buscando cada elemento en el string para reemplazarlo con una referencia.
    let finalString = string;

    if( elements2.length )
    finalString = replaceElementsInString( 1, finalString, elements2, '_group2');
    
    finalString = replaceElementsInString( 1, finalString, elements );
    
    // Otra vez recorriendo el array de elementos para replazar los elementos de referencia en el string
    // con un element HTML etiqueta "a".
    if( elements2.length )
    {
        const elementsValueFromContainer = elements2.map( item => {
            if( checkStringIsPhoneContainer( item ) )
                return item.match( regexPhoneFromContainer )[ 0 ];

            else if( checkStringIsUrlImageContainer( item ) )
                return item.match( regexUrlImageFromContainer )[ 0 ];
        });

        finalString = replaceElementsInString( 2, finalString, elementsValueFromContainer, '_group2', true );
    }

    finalString = replaceElementsInString( 2, finalString, elements );
    // console.log( "Replazando los elementos con referencias", finalString );

    return finalString.replace('  ', '').replace(/(\r\n|\r|\n){3,}/g, '\n\n');
}

const getUniqueValuesInArray = ( array ) => ( [ ...new Set( array ) ] );

const createStringReference = ( index ) => `[[{{--${ index }--}}]]`;

/**
 * Creando un elemento HTML, etiqueta a.
 * Se verifica el tipo de elemento que es para asignarle un prefijo en el href.
 * @param {*} string 
 * @param {*} verifyType 
 * @returns 
 */
const createA = ( string ) => {
    const elementA = document.createElement("a");

    // Asignando el texto a la etiqueta A.
    // elementA.textContent = string; // Asignando el texto del mismo href.
    // elementA.textContent = langStrings == 'en' ? 'here' : 'aqui'; // Asignando un texto común para todas las etiquetas A.
    // elementA.innerHTML = iconArrowRedirect; // Asignando un icono.
    elementA.innerHTML = typeTextForHyperLinks == 1 ? string : ( 
        typeTextForHyperLinks == 2 ? iconArrowRedirect : `${string} ${iconArrowRedirect}`
    );

    if( checkStringIsEmail( string ) )
    {
        elementA.href = `mailto:${ string }`;
    }
    else if( checkStringIsPhone( string ) && !checkStringIsUrl( string ) )
    {
        elementA.href = `tel:${ string }`;
    }
    else
    {
        elementA.href = checkIfStringHasHttp( string ) ? string : `https://${ string }`;
        elementA.target = "_blank";
    }

    return elementA;
}

/**
 * Recorriendo el array de elementos, buscando cada elemento en el string para replazarlo con una referencia.
 * @param {*} option 
 * @param {*} string 
 * @param {*} elements 
 * @returns 
 */
const replaceElementsInString = ( option, string, elements, labelReference = '', removeUrlItem = false ) => {

    // Si option es 1, buscara los elementos en el string y los replazara con una referencia.
    // Si option es 2, buscara las referencias en el string y los replazara con una los elementos con html.

    elements.map( ( item, index ) => {

        const itemReference = createStringReference( index + labelReference );
        const elementToFind = option == 1 ? item : itemReference;

        if( string.indexOf( elementToFind ) !== -1 )
        {
            const elementForReplace = option == 1 ? 
            itemReference 
            : 
            ( removeUrlItem && checkStringIsUrl( item ) ? '' : createA( item ).outerHTML );
            
            string = string.replace( elementToFind, elementForReplace );
            
            // console.log('array map option ', option, elementToFind, elementForReplace)
        }

    } );

    return string;
}

const checkStringIsEmail = ( string ) => string.match( regexEmail ) !== null;

const checkStringIsPhone = ( string ) => string.match( regexPhone ) !== null;

const checkStringIsDate = ( string ) => string.match( regexDate ) !== null;

const checkStringIsUrlImage = ( string ) => string.match( regexUrlImageFromContainer ) !== null;

const checkStringIsUrlImageContainer = ( string ) => string.match( regexUrlImageContainer ) !== null;

const checkStringIsPhoneContainer = ( string ) => string.match( regexPhoneWithContainer ) !== null;

const checkStringIsUrl = ( string ) => string.match( regexUrl ) !== null && !checkStringIsEmail( string );

const checkIfStringHasHttp = ( string ) => string.includes( 'http://' ) || string.includes( 'https://' );

/**
 * Verifica si el string contiene etiquetas HTML.
 * @param {*} string 
 * @returns 
 */
export const isHTML = (string) => {
    return Array.from(new DOMParser().parseFromString(string, "text/html").body.childNodes).some(({ nodeType }) => nodeType == 1);
}

/**
 * 
 * @param {*} url 
 * @returns 
 */
export const isAnImageUrl = ( url ) => {

    // Opción 1
    // Realiza un fetch al URL para obtener el header sobre el tipo de contenido.
    // return fetch( url, { method: 'HEAD' } )
    // .then( res => {

    //     // Comprueba si el tipo de contenido del URL comienza con el string "image".
    //     return res.headers.get( 'Content-Type' ).startsWith( 'image' );

    // } );

    // Opción 2
    const img = new Image();
    img.src = url;

    return new Promise( ( res ) => {
        img.onerror = () => res( false );
        img.onload = () => res( true );
    })
}